import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';
import Selectors from './components/Selectors';
import TableDetails from './components/TableDetails';

function App() {

  const [jobs, setJobs] = useState([]);

  const fetchCategories = async() => {
    let results = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/categories?populate[jobs][populate][experiences][populate]=ratings`)
    if(results?.data?.data?.length) {
      let listJobs = []
      results?.data?.data.forEach(element => {
        listJobs.push({
          category: {
            title: element.title,
            options: element.jobs
          }
        })
      });
      setJobs(JSON.parse(JSON.stringify([...listJobs])))
    }
  }

  useEffect(() => {
    fetchCategories();
  }, [])


  return (
    <div className="App">
      <div className='p-24 mx-auto'>
        <p className='text-6xl font-bold text-dark w-full text-center mb-24'>GRILLE DES SALAIRES ZETABOX 2023</p>
        <Selectors jobs={jobs}></Selectors>
        <TableDetails jobs={[...jobs, ...jobs, ...jobs, ...jobs, ...jobs, ...jobs ]}></TableDetails>
      </div>
    </div>
  );
}

export default App;
