import React, { useEffect, useState } from 'react'
import Selector from './Selector'
import { StarIcon, UserGroupIcon, ChartBarSquareIcon } from '@heroicons/react/24/solid';

const Selectors = ({jobs}) => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [selectedRating, setSelectedRating] = useState(null);
  const [experiences, setExperiences] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [minSalary, setMinSalary] = useState(null);
  const [maxSalary, setMaxSalary] = useState(null);

  const formatExperiences = () => {
    return selectedJob.experiences.map(experience => {
      let title = experience.title;
      if(experience.max_experience && experience.min_experience) {
        title += ` (${experience.min_experience} - ${experience.max_experience} ans)`
      } else if(experience.min_experience) {
        title += ` (+${experience.min_experience} ans)`
      }
      return {...experience, title}
    })
  }
  const formatRatings = () => {
    return selectedExperience.ratings.map(rating => {
      let title = "";
      console.log(rating)
      if(rating.min === rating.max) {
        title = rating.min
      } else {
        title = `${rating.min} - ${rating.max}`
      }
      return {...rating, title}
    })
  }

  useEffect(() => {
    if(selectedJob) {
      setExperiences(formatExperiences())
      setSelectedExperience(null);
      setSelectedRating(null);
      setMaxSalary(null);
      setMinSalary(null);
    }
  }, [selectedJob])

  useEffect(() => {
    if(selectedExperience) {
      setMaxSalary(selectedExperience.max_salary)
      setMinSalary(selectedExperience.min_salary)
      setRatings(formatRatings())
    }
    setSelectedRating(null);
  }, [selectedExperience])

  useEffect(() => {
    if(selectedRating) {
      setMaxSalary(selectedRating.salary)
      setMinSalary(null)
    }
  }, [selectedRating])

  return (
    <div className='flex gap-4'>
      <Selector 
        fullWidth={true}
        customIcon={<UserGroupIcon/>}
        title="Métier"
        options={jobs}
        selectedOption={selectedJob}
        setSelectedOption={setSelectedJob}
        enabled={true}
      ></Selector>
      <p className='text-2xl font-bold flex items-center text-[#c91961]'>+</p>
      <Selector 
        customIcon={<ChartBarSquareIcon/>}
        title="Expérience"
        options={experiences}
        selectedOption={selectedExperience}
        setSelectedOption={setSelectedExperience}
        enabled={!!selectedJob}
      ></Selector>
      <p className='text-2xl font-bold flex items-center text-[#c91961]'>+</p>
      <Selector 
        customIcon={<StarIcon/>}
        title="Rating"
        options={ratings}
        selectedOption={selectedRating}
        setSelectedOption={setSelectedRating}
        enabled={!!selectedJob && !!selectedExperience}
      ></Selector>
      <p className='text-2xl font-bold flex items-center text-[#c91961]'>=</p>
      <div className="relative rounded-md flex items-center w-full">
      <div className={`text-right border border-[#a1a1a1] rounded-md h-16 flex items-center justify-end p-4 gap-2 w-full ${!!selectedJob && !!selectedExperience && (minSalary || maxSalary) ? "bg-white" : "bg-gray-200"}`}>
        {minSalary && maxSalary && <span>{minSalary} - {maxSalary} </span>}
        {minSalary && !maxSalary && <span>&gt; {minSalary} </span>}
        {!minSalary && maxSalary && <span>{maxSalary}</span>}
        TND
      </div></div>
    </div>
  )
}

export default Selectors
