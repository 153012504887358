import React, { useRef, useState, useEffect } from 'react';
import { ChevronDownIcon, UserGroupIcon } from '@heroicons/react/24/solid';

const Selector = ({ customIcon, title, options, selectedOption, setSelectedOption, enabled, fullWidth }) => {
  const dropdownRef = useRef(null);
  const [showChilds, setShowChilds] = useState(false);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowChilds(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const selectAnOption = (option) => {
    setSelectedOption(option);
    setShowChilds(false);
  }

  const handleShowShilds = () => {
    if(enabled) {
      setShowChilds(!showChilds);
    }
  }

  return (
    <div ref={dropdownRef} className={`relative rounded-md flex items-center ${fullWidth ? "min-w-[400px]" : "w-full"}`}>
      <div className={`border border-[#a1a1a1] rounded-md h-16 flex items-center justify-between p-4 gap-2 w-full ${enabled ? "bg-white" : "bg-gray-200"}`} onClick={() => handleShowShilds()}>
        <div className='flex gap-2'>
          {customIcon && React.cloneElement(customIcon, { className: "h-6 w-6 text-[#441e30]" })}
          {selectedOption ? <p>{selectedOption.title}</p> : <p>{title}</p>}
        </div>
        <ChevronDownIcon className={`h-6 w-6 text-[#441e30] duration-300 transform ${showChilds ? 'rotate-180' : 'rotate-0'}`} />
      </div>
      <div className={`text-sm text-left max-h-[200px] overflow-y-auto duration-300 absolute bg-white w-full rounded-md z-40 px-2 border border-[#a1a1a1] top-16 p-4 right-0 transition-opacity capitalize ${showChilds ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>
        {options.map(el => (
          el.category ? 
          <div>
            <p className='font-bold px-2 py-2 text-dark'>{el.category.title}</p>
            {el.category.options.map(item => 
              <p className='text-dark pl-4 pr-2 hover:bg-dark hover:text-white py-1' onClick={() => selectAnOption(item)}>{item.title}</p>  
            )}
          </div> : <p className='text-dark pl-4 pr-2 hover:bg-dark hover:text-white py-1' onClick={() => selectAnOption(el)}>{el.title}</p>  
        ))}
      </div>
    </div>
  );
};

export default Selector;
