import React from 'react'
import Collapse from './Collapse'

const TableDetails = ({jobs}) => {
  
  const styles = [ {
    text: "text-[#1072b9]",
    bgPrimary: "bg-[#1072b9]",
    bgSecondary: "bg-[#d9e0f3]",
  }, {
    text: "text-[#441e30]",
    bgPrimary: "bg-[#441e30]",
    bgSecondary: "bg-[#d0c4ca]",
  }, {
    text: "text-[#0197a7]",
    bgPrimary: "bg-[#0197a7]",
    bgSecondary: "bg-[#dde9ee]",
  }, {
    text: "text-[#c91962]",
    bgPrimary: "bg-[#c91962]",
    bgSecondary: "bg-[#f4d9e0]",
  }, {
    text: "text-[#eda414]",
    bgPrimary: "bg-[#eda414]",
    bgSecondary: "bg-[#fdedd7]",
  }, {
    text: "text-[#7378b8]",
    bgPrimary: "bg-[#7378b8]",
    bgSecondary: "bg-[#e3e3f3]",
  }, {
    text: "text-[#025f0d]",
    bgPrimary: "bg-[#025f0d]",
    bgSecondary: "bg-[#d4dbd4]",
  }]
  return (
    <div className='mt-16 h-screen'>
      {jobs.map((el, i) => <Collapse job={el} style={styles[i] || styles[i - 7]}></Collapse>)}
    </div>
  )
}

export default TableDetails
