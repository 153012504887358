import React, { useEffect, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

const Collapse = ({job, style}) => {
  const [filtered, setFiltered] = useState(null)
  const [search, setSearch] = useState("")
  const [show, setShow] = useState(false);

  const toggleContent = () => {
    setShow(!show);
  };


  return (
    <div className='border-b border-red'>
      <div 
        onClick={toggleContent} 
        className={`duration-300 py-2 min-h-[60px] flex items-center px-2 justify-between w-full border-b border-white ${show ? "text-white " + style.bgPrimary : style.text}`}
      >
        <div className='flex justify-between w-full'>
          <h2 className='text-xl leading-none font-bold'>{job.category.title}</h2>
          <ChevronDownIcon className={`h-6 w-6 duration-300 ${show ? "rotate-180" : "" }`} ></ChevronDownIcon>
        </div>
      </div>
      <div className={`font-semibold text-sm origin-top overflow-hidden duration-300 ${show ? 'max-h-[100vh] scale-y-100' : 'max-h-0 scale-y-0'}`} >
        <div className={`px-2 flex  ${show ? "text-white " + style.bgPrimary : style.text}`}>
          <div className='w-full text-left py-2 min-h-[50px] flex items-center'>Profil</div>
          {job.category.options[0].experiences.map(experience => 
            <div className='w-[120px] h-[50px] flex flex-col justify-center px-2 border-l border-white'>
              {experience.title} 
              {experience.max_experience && experience.min_experience ? <p>{`${experience.min_experience} - ${experience.max_experience} ans`}</p> : ""}
              {!experience.max_experience && experience.min_experience ? <p>{`< ${experience.min_experience} ans`}</p> : ""}
            </div>
          )}
        </div>
        {[...job.category.options, ...job.category.options].map((option, i) => <div className={`flex px-2 ${i % 2 ? style.bgSecondary : ""}`}>
          <div className='w-full text-left py-2 min-h-[50px] flex justify-center flex-col'>
            <p className={`font-semibold ${style.text}`}>{option.title}</p>
            <p className="text-[10px] ">{option.description}</p>
            
          </div>
          {option.experiences.map(experience => 
            <div className={`${style.text} w-[120px] flex flex-col justify-center px-2`}>
              {experience.max_salary && experience.min_salary ? <p>{`${experience.min_salary} - ${experience.max_salary}`}</p> : ""}
              {!experience.max_salary && experience.min_salary ? <p>{`< ${experience.min_salary}`}</p> : ""}
              {!experience.max_salary && !experience.min_salary ? <p>-</p> : ""}
            </div>
          )}
        </div>)}
      </div>
    </div>
  );
};

export default Collapse;
